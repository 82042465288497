import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { AlertController, MenuController, Platform } from '@ionic/angular';
import { ApiService } from './services/api.service';
import { AlertServiceService } from './services/alert-service.service';
import { StorageService } from './services/storage.service';
import { Router } from '@angular/router';
import { NavigationService } from './services/navigation.service';
import { Events } from './services/events';
import { SplashScreen } from '@capacitor/splash-screen';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { environment } from "../environments/environment";

import { StatusBar, Style } from '@capacitor/status-bar';
import { IonicKeyboardAssist } from 'ionic-keyboard-assist';



@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})

export class AppComponent {
    deviceData: any;
    uuid: string;
    model: any;
    device_platform: any;
    device_version: any;
    loginUser: any = [];
    menuPages: any = [];
    user_role: any;
    adminMember: number;
    constructor(
        private platform: Platform,
        public storage: StorageService,
        public apiService: ApiService,
        public alertServiceService: AlertServiceService,
        private menu: MenuController,
        private naviGation: NavigationService,
        public router: Router,
        private storageService: StorageService,
        private cd: ChangeDetectorRef,
        private events: Events,
        private alertCtrl: AlertController,
        private keyboardAssist: IonicKeyboardAssist

    ) {

        // this.keyboardAssist.init();

        let devicePlatform = Capacitor.getPlatform();
        if (devicePlatform == 'web') {
            this.keyboardAssist.init({ scrollPadding: true, scrollAssist: true });
        }

        this.events.subscribe('sideMenu_role', ((loginRole) => {
            if (loginRole) {
                this.user_role = loginRole.userRole;
                if (loginRole.isAdmin) {
                    this.adminMember = +loginRole.isAdmin;
                }
                console.log('user_role:>>', this.user_role);
                console.log('adminMember:>>', this.adminMember);
                this.roleWiseMenu();
            }
        }));
        this.getScreenSize();

        this.events.subscribe('userLogedIn', ((userData) => {
            if (userData['login']) {
                this.loginUser = userData['loginUserData'];
            } else {
                this.loginUser = [];
            }

        }))

        this.storageService.get('crypto_user').then((user) => {
            if (user != null) {
                this.loginUser = JSON.parse(user);
                console.log('login USer:>>', this.loginUser);
            } else {
                this.router.navigate(['/login']);
            }
        }, (err) => {
            this.router.navigate(['/login']);
        })
        this.initializeApp();
    }

    ngOnInit() {
        let devicePlatform = Capacitor.getPlatform();
        console.log('devicePlatform~~~~:>', devicePlatform)
        if (devicePlatform == 'web') {
            this.requestPermission();
            this.listen();
        } else {
            this.pushNotificationSection();
        }
        if (Capacitor.isPluginAvailable('StatusBar')) {
            StatusBar.setOverlaysWebView({ overlay: false });
        }

    }


    requestPermission() {
        const messaging = getMessaging();
        getToken(messaging,
            { vapidKey: environment.firebaseConfig.validKey }).then(
                (currentToken) => {
                    if (currentToken) {
                        console.log("Hurraaa!!! we got the token.....");
                        console.log(currentToken);
                        this.storageService.set('device_token', currentToken);
                    } else {
                        console.log('No registration token available. Request permission to generate one.');
                    }
                }).catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                });
    }




    listen() {
        const messaging = getMessaging();
        console.log('messaging:>>', messaging);
        onMessage(messaging, (payload) => {
            this.webNotificationAlert('Notification', payload.notification.body);
            console.log('Message received. ', payload);
        });
    }


    initializeApp() {


        this.platform.ready().then(async () => {

            setTimeout(() => {
                SplashScreen.hide();
            }, 5000);

            let storageData = await this.storage.get('crypto_user');
            if (storageData != null) {
                this.apiService.firebaseAuthToken();
                this.user_role = this.loginUser.user_role;

                if (this.user_role == 'member') {
                    if (this.loginUser.member_permission) {
                        this.adminMember = +this.loginUser.member_permission.is_admin_account;
                    }
                }

                if (this.user_role == 'contributor') {
                    this.naviGation.goToRoot('/tabs/home-contributor');
                } else if (this.user_role == 'collective') {
                    this.naviGation.goToRoot('/tabs/home');
                } else if (this.user_role == 'member') {
                    this.naviGation.goToRoot('/tabs/profile');
                } else {
                    this.naviGation.goToRoot('/tabs/home-athlete-home');
                }
                this.roleWiseMenu();
                // this.pushNotificationSection();
            } else {
                this.naviGation.setRoot('/login');
            }
        }
        );
    }

    roleWiseMenu() {
        if (this.user_role == 'contributor') {
            this.menuPages = [
                {
                    title: 'PROFILE',
                    url: '/profile',
                },
                {
                    title: 'CONTACT TECH SUPPORT',
                    url: '/contact-us',
                },
                {
                    title: 'Settings',
                    url: '/settings',
                },
            ];
        }

        if (this.user_role == 'collective') {
            this.menuPages = [
                {
                    title: 'LEGAL LIBRARY',
                    url: '/legal-library',
                },
                {
                    title: 'IMAGE ARCHIVE',
                    url: '/image-archive',
                },
                {
                    title: 'TAX CENTER',
                    url: '/tax-center',
                },
                {
                    title: 'PROFILE',
                    url: '/profile',
                },
                {
                    title: 'MEMBERS',
                    url: '/members',
                },
                {
                    title: 'CONTACT TECH SUPPORT',
                    url: '/contact-us',
                },
                {
                    title: 'Settings',
                    url: '/settings',
                },
            ];

        }
        if (this.user_role == 'athlete') {
            this.menuPages = [
                {
                    title: 'CONTACT US',
                    url: '/contact-us',
                },
                {
                    title: 'AGREEMENTS',
                    url: '/service-agreement',
                },
                {
                    title: 'LEGAL LIBRARY',
                    url: '/legal-library',
                },
                {
                    title: 'IMAGE ARCHIVE',
                    url: '/athelet-image-archive',
                },
                {
                    title: 'ACCOUNT SETTINGS',
                    url: '/settings'
                }
            ];

        }

        if (this.user_role == 'member') {
            this.menuPages = [];
            if (this.adminMember == 1) {
                this.menuPages.push({
                    title: 'LEGAL LIBRARY',
                    url: '/legal-library',
                });
            }
            this.menuPages.push({
                title: 'Settings',
                url: '/settings',
            });

        }

        this.menuPages.push({
            title: 'Tutorial',
            url: '/tutorial',
        });

        this.cd.detectChanges();
    }


    pushNotificationSection() {
        const isNotificationPlugin = Capacitor.isPluginAvailable('PushNotifications');
        console.log('isNotificationPlugin:>>', isNotificationPlugin);
        if (isNotificationPlugin) {

            // Request permission to use push notifications
            // iOS will prompt user and return if they granted permission or not
            // Android will just grant without prompting
            PushNotifications.requestPermissions().then(result => {
                if (result.receive === 'granted') {
                    // Register with Apple / Google to receive push via APNS/FCM
                    PushNotifications.register();
                } else {
                    // Show some error
                    console.log('error:>>', 'not granted');
                }
            });

            // On success, we should be able to receive notifications
            PushNotifications.addListener('registration',
                (token: Token) => {
                    console.log('Push registration success, token: ' + token.value);
                    this.storageService.set('device_token', token.value);
                }
            );

            // Some issue with our setup and push will not work
            PushNotifications.addListener('registrationError',
                (error: any) => {
                    console.log('Error on registration: ' + JSON.stringify(error));
                }
            );

            // Show us the notification payload if the app is open on our device
            PushNotifications.addListener('pushNotificationReceived',
                (notification: PushNotificationSchema) => {
                    console.log('Push received: ' + JSON.stringify(notification));
                }
            );

            // Method called when tapping on a notification
            PushNotifications.addListener('pushNotificationActionPerformed',
                (notification: ActionPerformed) => {
                    let rcvdNotification = JSON.stringify(notification)
                    console.log('Push action performed: ' + rcvdNotification);
                    let notificationData = notification.notification.data;
                    console.log('notificationData:>>', notificationData);

                    if (notificationData.type == 'task_submition' || notificationData.type == 'task_approved' || notificationData.type == 'task_denied') {
                        this.router.navigate(['/approve-task'], { queryParams: { taskId: notificationData.item_id } });
                    }

                    if (notificationData.type == "send_library") {
                        this.router.navigate(['/selected-document'], { queryParams: { libraryData: JSON.stringify(notificationData.libraryData), documentType: 'library' } });
                    }

                }
            );
        }

    }

    async saveLogoutDevice(deviceToken) {
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        console.log('timeZone:>>', timeZone);
        let deviceId = await Device.getId();
        console.log('deviceId:>>', deviceId);

        let deviceInfo = await Device.getInfo();
        console.log('deviceInfo:>>', deviceInfo);

        let apiData = {
            token: this.loginUser.token,
            uuid: deviceId.uuid,
            device_token: deviceToken,
            platform: deviceInfo.platform,
            timezone: timeZone,
            action_type: 'logout'

        }
        console.log('apiData:>>', apiData);
        this.apiService.sendData('save-device-info', apiData).subscribe((res) => {
            if (res['status'] == 'ok') {
                console.log('res:>>', res);
            }
        }, (err) => {
            console.log('error:>>', err);
        })
    }


    isModalOpen = false;
    modalAction: any = '';
    setOpen(isOpen: boolean) {
        this.isModalOpen = isOpen;
    }

    logOutConfirm() {
        this.apiService.firebaseSignOut();
        let device_token = '';
        this.storage.get('device_token').then((deviceToken) => {
            this.isModalOpen = false;
            if (deviceToken != null) {
                device_token = deviceToken;
                this.saveLogoutDevice(device_token);
            }
            console.log('device_token:>', device_token);
        })
        this.storage.remove('crypto_user');
        this.menu.enable(false);
        this.naviGation.setRoot('/login');
    }

    cancel() {
        this.modalAction = 'cancel'
        this.isModalOpen = false;
    }


    async webNotificationAlert(heading, msg) {
        let alert = await this.alertCtrl.create({
            header: heading,
            message: msg,
            buttons: [{
                text: 'OK',
                handler: () => {
                }
            }]
        });
        await alert.present();
    }

    toggleMenu() {
        this.menu.toggle(); //Add this method to your button click function
    }


    side: string = 'end';
    scrWidth: any;
    @HostListener('window:resize', ['$event'])

    getScreenSize() {
        this.scrWidth = window.innerWidth;
        console.log('scrWidth:>>', this.scrWidth);
        // this.side = event.target.scrWidth > 992 ? this.side='start' : this.side='end';
        if (this.scrWidth > 992) {
            this.side = 'start';
        }

        console.log(this.scrWidth);
    }

}
