import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
    },
    {
        path: 'login',
        loadChildren: () => import('./beforeLoginPages/login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'signup',
        loadChildren: () => import('./beforeLoginPages/signup/signup.module').then(m => m.SignupPageModule)
    },
    {
        path: 'forgot-password',
        loadChildren: () => import('./beforeLoginPages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
    },
    {
        path: 'reset-password',
        loadChildren: () => import('./beforeLoginPages/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
    },
    {
        path: 'spacial-code',
        loadChildren: () => import('./beforeLoginPages/spacial-code/spacial-code.module').then(m => m.SpacialCodePageModule)
    },
    {
        path: 'about-us',
        loadChildren: () => import('./commonpages/about-us/about-us.module').then(m => m.AboutUsPageModule)
    },
    {
        path: 'contact-us',
        loadChildren: () => import('./commonpages/contact-us/contact-us.module').then(m => m.ContactUsPageModule)
    },
    {
        path: 'change-password',
        loadChildren: () => import('./commonpages/change-password/change-password.module').then(m => m.ChangePasswordPageModule)
    },
    {
        path: 'help',
        loadChildren: () => import('./commonpages/help/help.module').then(m => m.HelpPageModule)
    },
    {
        path: 'edit-profile',
        loadChildren: () => import('./commonpages/edit-profile/edit-profile.module').then(m => m.EditProfilePageModule)
    },
    {
        path: 'inccorect-email',
        loadChildren: () => import('./beforeLoginPages/inccorect-email/inccorect-email.module').then(m => m.InccorectEmailPageModule)
    },
    {
        path: 'profile',
        loadChildren: () => import('./commonpages/profile/profile.module').then(m => m.ProfilePageModule)
    },
    {
        path: 'contact-us',
        loadChildren: () => import('./commonpages/contact-us/contact-us.module').then(m => m.ContactUsPageModule)
    },
    {
        path: 'change-password',
        loadChildren: () => import('./commonpages/change-password/change-password.module').then(m => m.ChangePasswordPageModule)
    },
    {
        path: 'help',
        loadChildren: () => import('./commonpages/help/help.module').then(m => m.HelpPageModule)
    },
    {
        path: 'wallet',
        loadChildren: () => import('./tabs/wallet/wallet.module').then(m => m.WalletPageModule)
    },
    {
        path: 'edit-profile',
        loadChildren: () => import('./commonpages/edit-profile/edit-profile.module').then(m => m.EditProfilePageModule)
    },
    {
        path: 'notifications',
        loadChildren: () => import('./commonpages/notifications/notifications.module').then(m => m.NotificationsPageModule)
    },
    {
        path: 'inccorect-email',
        loadChildren: () => import('./beforeLoginPages/inccorect-email/inccorect-email.module').then(m => m.InccorectEmailPageModule)
    },
    {
        path: 'messages',
        loadChildren: () => import('./pages/messages/messages.module').then(m => m.MessagesPageModule)
    },
    {
        path: 'selected-message',
        loadChildren: () => import('./pages/selected-message/selected-message.module').then(m => m.SelectedMessagePageModule)
    },
    {
        path: 'legal-library',
        loadChildren: () => import('./commonpages/legal-library/legal-library.module').then(m => m.LegalLibraryPageModule)
    },
    {
        path: 'new-document',
        loadChildren: () => import('./pages/new-document/new-document.module').then(m => m.NewDocumentPageModule)
    },
    {
        path: 'image-archive',
        loadChildren: () => import('./pages/image-archive/image-archive.module').then(m => m.ImageArchivePageModule)
    },
    {
        path: 'members',
        loadChildren: () => import('./pages/members/members.module').then(m => m.MembersPageModule)
    },
    {
        path: 'tax-center',
        loadChildren: () => import('./pages/tax-center/tax-center.module').then(m => m.TaxCenterPageModule)
    },
    {
        path: 'selected-document',
        loadChildren: () => import('./pages/selected-document/selected-document.module').then(m => m.SelectedDocumentPageModule)
    },
    {
        path: 'search-team',
        loadChildren: () => import('./pages/search-team/search-team.module').then(m => m.SearchTeamPageModule)
    },
    {
        path: 'team-images',
        loadChildren: () => import('./pages/team-images/team-images.module').then(m => m.TeamImagesPageModule)
    },
    {
        path: 'set-permission',
        loadChildren: () => import('./pages/set-permission/set-permission.module').then(m => m.SetPermissionPageModule)
    },
    {
        path: 'settings',
        loadChildren: () => import('./commonpages/settings/settings.module').then(m => m.SettingsPageModule)
    },
    {
        path: 'selected-task',
        loadChildren: () => import('./pages/selected-task/selected-task.module').then(m => m.SelectedTaskPageModule)
    },
    {
        path: 'complete-task',
        loadChildren: () => import('./pages/complete-task/complete-task.module').then(m => m.CompleteTaskPageModule)
    },
    {
        path: 'schedule-history',
        loadChildren: () => import('./pages/schedule-history/schedule-history.module').then(m => m.ScheduleHistoryPageModule)
    },
    {
        path: 'tutorial',
        loadChildren: () => import('./pages/tutorial/tutorial.module').then(m => m.TutorialPageModule)
    },
    {
        path: 'add-player',
        loadChildren: () => import('./pages/add-player/add-player.module').then(m => m.AddPlayerPageModule)
    },
    {
        path: 'selected-player',
        loadChildren: () => import('./pages/selected-player/selected-player.module').then(m => m.SelectedPlayerPageModule)
    },
    {
        path: 'new-task',
        loadChildren: () => import('./pages/new-task/new-task.module').then(m => m.NewTaskPageModule)
    },
    {
        path: 'search-player',
        loadChildren: () => import('./pages/search-player/search-player.module').then(m => m.SearchPlayerPageModule)
    },
    {
        path: 'service-agreement',
        loadChildren: () => import('./pages/service-agreement/service-agreement.module').then(m => m.ServiceAgreementPageModule)
    },
    {
        path: 'athelet-image-archive',
        loadChildren: () => import('./pages/athelet-image-archive/athelet-image-archive.module').then(m => m.AtheletImageArchivePageModule)
    },
    {
        path: 'upload-image-archive',
        loadChildren: () => import('./pages/upload-image-archive/upload-image-archive.module').then(m => m.UploadImageArchivePageModule)
    },
    {
        path: 'selected-archive-team',
        loadChildren: () => import('./pages/selected-team/selected-team.module').then(m => m.SelectedTeamPageModule)
    },
    {
        path: 'approve-task',
        loadChildren: () => import('./pages/approve-task/approve-task.module').then(m => m.ApproveTaskPageModule)
    },
    {
        path: 'player-images',
        loadChildren: () => import('./pages/player-images/player-images.module').then(m => m.PlayerImagesPageModule)
    },
    {
        path: 'new-member',
        loadChildren: () => import('./pages/new-member/new-member.module').then(m => m.NewMemberPageModule)
    },
    {
        path: 'delete-account',
        loadChildren: () => import('./commonpages/delete-account/delete-account.module').then(m => m.DeleteAccountPageModule)
    },
    {
        path: 'task-list',
        loadChildren: () => import('./pages/task-list/task-list.module').then(m => m.TaskListPageModule)
    },
    {
        path: 'team-contributors',
        loadChildren: () => import('./pages/team-contributors/team-contributors.module').then(m => m.TeamContributorsPageModule)
    },
    {
        path: 'calender-players',
        loadChildren: () => import('./pages/calender-players/calender-players.module').then(m => m.CalenderPlayersPageModule)
    },
    {
        path: 'billing',
        loadChildren: () => import('../app/commonpages/billing/billing.module').then(m => m.BillingPageModule)
    },
    {
        path: 'billing/:card_id/:card_last4/:card_brand/:card_exp_month/:card_exp_year/:card_name/:action',
        loadChildren: () => import('../app/commonpages/billing/billing.module').then(m => m.BillingPageModule)
    },
  {
    path: 'add-payment-method',
    loadChildren: () => import('./commonpages/add-payment-method/add-payment-method.module').then( m => m.AddPaymentMethodPageModule)
  },
  {
    path: 'list-cards',
    loadChildren: () => import('./commonpages/list-cards/list-cards.module').then( m => m.ListCardsPageModule)
  },
  {
    path: 'add-bank-account',
    loadChildren: () => import('./commonpages/add-bank-account/add-bank-account.module').then( m => m.AddBankAccountPageModule)
  },
  {
    path: 'payout/:amount',
    loadChildren: () => import('./commonpages/payout/payout.module').then( m => m.PayoutPageModule)
  },
  {
    path: 'payout-list',
    loadChildren: () => import('./pages/payout-list/payout-list.module').then( m => m.PayoutListPageModule)
  },
  {
    path: 'primer-suggestion',
    loadChildren: () => import('./pages/primer-suggestion/primer-suggestion.module').then( m => m.PrimerSuggestionPageModule)
  }

];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
